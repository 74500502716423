// firebase/index
export const firebaseConfig = {
    apiKey: "AIzaSyAl7-Xtk0aKzHHhSnW3OrF5joiOyvlSLUM",
    authDomain: "expocapipe-2abc0.firebaseapp.com",
    databaseURL: "https://expocapipe-2abc0.firebaseio.com",
    projectId: "expocapipe-2abc0",
    storageBucket: "expocapipe-2abc0.appspot.com",
    messagingSenderId: "1091437518841",
    appId: "1:1091437518841:web:846d4dacad0e1dcddc6728",
    measurementId: "G-73D665JD7K"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/expocapipe-2abc0.appspot.com/o/'